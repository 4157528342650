<template>

  <div class="">

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <!-- Produtos -->
    <div class="support-company-area section_pad-100">
      <div class="container">
        <div 
          class="row mb-5"
        >
          <div class="col-xl-6 col-lg-6 col-md-10 text-center mb-5 mb-lg-0">
            <img v-if="produto.imagens!=''"
              :src="$apiUrlIndex + produtoimg[0].urlImg"
              alt="Imagem"
            >
            
          </div>
          <div class="col-xl-5 col-lg-5 col-md-10">
            <div class="right-caption mb-4">
              <div class="section-tittle">
                <h2 class="title-topic text-red">{{ produto.nomeProduto }}</h2>                  
              </div>
              <div class="product-text">
                <p v-html="produto.descricao"></p>
                 <p v-html="produto.descricao2"></p>
                  <p v-html="produto.descricao3"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Galeria -->
    <section 
      class="galeria"
    >
      <div class="container-fluid">
        <div class="row">
          <div 
            class="col-6 col-md galeria-item px-0"
            v-for="imagem in produtoimg.slice(1)"
            :key="imagem.produtoImagemId"
          >
            <img :src="$apiUrlIndex + imagem.urlImg">
          </div>
        </div>
      </div>
    </section>

    <!-- Video -->
    <section 
      class="video section_pad-100"
      style="background-color: #f2f0ea;"
    >
      <div class="container">
        <div class="row mb-5">
          <div class="col text-center heading-section">          
            <div class="text-center">
              <h2 class="title-h2 text-blue">Vídeo institucional</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">          
            <iframe 
              v-if="produtoVideo != ''"
              width="100%" 
              height="450" 
              :src="produtoVideo" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen
            >
            </iframe> 
            <img 
              v-else
              src="/img/indisponivel.jpg"
            >          
            </div>
        </div>
      </div>
    </section>

    <!-- Detalhes técnicos (table) -->
    <section 
      v-if="dadoTecnico !=''"
      class="detalhes-tecnicos">
      <img class="w-100" src="/img/28.png">
      <div class="container my-5">
        <div class="row justify-content-center pb-5">
          <div class="col">
            <div class="section-tittle text-center">
              <h2 class="title-topic text-red">Detalhes Técnicos</h2>                  
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table table-striped">
              <tbody>
                <tr v-for="dados in dadosTecnicos" :key="dados.produtoInfoAdicionalId"> 
                  <td>{{ dados.tituloInfo }}</td>
                  <td><span v-html="dados.descricaoInfo"></span></td>
                </tr>
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>


    <!-- Banner -->
    <section 
      class="cta_2 set-img section_pad-100"
      :style="{ backgroundImage: 'url(' + bannerProdutos_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-7 offset-md-5 col-lg-6 offset-lg-6">
            <div class="cta_2-img mb-5">
              <!-- Logo -->
              <img src="/img/18.png">
            </div>
            <div class="cta_2-text mb-100">
              <p 
                class="text-white"
                v-html="bannerProdutosDescricao1"
              ></p>
                
              
            </div>
            <div class="cta_2-text mb-100">
              <span v-html="bannerProdutosDescricao2"></span>
            </div>
            <div class="cta_2-btn">
              <a 
                :href="bannerProdutosBtnLink"
                class="btn-rounded btn-3 btn-pointer"
              >
                {{ bannerProdutosBtn }}
              </a>
            </div>

          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'

export default {
  components: {
    TopoInternas,
  },
  data() {
    return {
      
      topoTitulo: '',
      topo_bg: '',

      produto: [],
      produtoimg:[],
      produtoVideo: '',
      dadosTecnicos: [],
      dadoTecnico: '',
      // Var que recebe o parâmetro que passa o id 
      // (this.id)
      id: this.$route.params.id,

      bannerProdutos: [],
      bannerProdutos_bg: '',
      bannerProdutosDescricao1: '',
      bannerProdutosDescricao2: '',
      bannerProdutosBtn: '',
      bannerProdutosBtnLink: '',

    }
  },
  created() {

    // Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoProdutos = retornoAPI
      
      this.topo_bg = this.$apiUrlIndex + this.topoProdutos[0].institucionalImg[0].urlImagem
      this.topoTitulo = this.topoProdutos[0].nome

    });
    
    
    // Cadastro Produtos e video   
    this.$http
    .get(this.$apiUrl + "/produto/" + parseInt(this.id,10))
    .then((res) => res.json())
    .then((retornoAPI) => {
    this.produto = retornoAPI

    this.produtoVideo = this.produto.tempoEntrega

    this.produtoimg = this.produto.imagens.sort(function (a, b) {
      if (a.ordem > b.ordem) { return 1; }   
      if (a.ordem < b.ordem) { return -1; } 
      return 0;
    })

    console.log('get video >>>>> ' + this.produtoVideo)


      // Dados Técnicos
      this.$http
      .get(this.$apiUrl + "/ProdutoInfoAdicional/Produto/" + parseInt(this.id,10))
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.dadosTecnicos = retornoAPI
        this.dadosTecnicos.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }   
          if (a.ordem < b.ordem) { return -1; } 
          return 0;
        })

        this.dadoTecnico = this.dadosTecnicos[0].tituloInfo

      });

    });

    // Banner
    this.$http
    .get(this.$apiUrl + "/institucional/telas/banner%20inferior")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.bannerProdutos = retornoAPI
      
      this.bannerProdutos_bg = this.$apiUrlIndex + this.bannerProdutos[0].institucionalImg[0].urlImagem
      this.bannerProdutosDescricao1 = this.bannerProdutos[0].descricao1
      this.bannerProdutosDescricao2 = this.bannerProdutos[0].descricao2
      this.bannerProdutosBtn = this.bannerProdutos[0].textoBtn1
      this.bannerProdutosBtnLink = this.bannerProdutos[0].linkBtn1

    });
    

  }

  
}
</script>


