<template>
  
  <!-- Footer -->
  <footer>
    <div 
      class="footer-wrapper set-img"
      :style="{ backgroundImage: 'url(' + footer_bg + ')' }"
    >
      <div class="footer-area footer-padding">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Logo + Sociais -->
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
              <div class="single-footer-caption mb-50">
                <div class="single-footer-caption mb-30">
                  <!-- logo -->
                  <div class="footer-logo mb-35">
                    <router-link to="/">
                      <img src="/img/18.png">                        
                    </router-link>
                  </div>
                  <div class="footer-tittle">
                    <div class="footer-pera">
                      <p v-html="footerDescricao"></p>
                    </div>
                    <!-- social -->
                    <div class="footer-social">
                      <a v-if="sociaisTwitter!=null" :href="sociaisTwitter" target="_blank"><i class="fab fa-twitter"></i></a>
                      <a v-if="sociaisFacebook!=null" :href="sociaisFacebook" target="_blank"><i class="fab fa-facebook"></i></a>    
                      <a v-if="sociaisInstagram!=null" :href="sociaisInstagram" target="_blank"><i class="fab fa-instagram"></i></a>
                      <a v-if="sociaisYoutube!=null" :href="sociaisYoutube" target="_blank"><i class="fab fa-youtube"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Institucional -->
            <div class="col-xl-2 offset-xl-1 col-lg-2 col-md-4 col-sm-6">
              <div class="single-footer-caption mb-50">
                <div class="footer-tittle">
                  <h4>Menu</h4>
                  <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/sobre">Sobre Nós</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/contato">Contato</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Produtos -->
            <div class="col-lg-3 col-md-8 col-sm-6" id="produtos">
              <div class="single-footer-caption mb-50">
                <div class="footer-tittle">
                  <h4>Nossos Produtos</h4>
                  <ul>
                    <li
                      v-for="item in listaProdutos"
                      :key="item.produtoId"
                    >
                      <a 
                       
                        href="#" 
                        @click="$redirect_reload('/produtos/' + item.nomeProduto.replaceAll(' ','-').replaceAll('/','') + '/' + item.produtoId)"
                      >
                        {{ item.nomeProduto }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- COntatos -->
            <div class="col-lg-3 col-md-4 col-sm-8">
              <div class="single-footer-caption mb-50">
                <div class="footer-tittle mb-20">
                  <h4>Contatos</h4>
                  <p v-html="footerContatos"></p>
                </div>
                <!-- <ul class="mb-20">
                  <li class="number"><a href="#"><span class="text-white">(51) </span> 738-9083</a></li>
                  <li class="number"><a href="#"><span class="text-white">(51) </span> 738-9083</a></li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer-bottom area -->
      <div class="footer-bottom-area">
        <div class="container">
          <div class="footer-border">
            <div class="row py-5">
              <div class="col-xl-12">
                <div class="footer-copy-right text-center">
                  <a 
                    href="https://avancedigital.com.br/" 
                    target="_blank"
                  >
                    <img class="logo_avance" src="/img/logo-avance.png">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

  export default {
    name: 'App',
    components: {

    },
    data() {
      return {
        
        footer: [],
        footer_bg: '',
        footerDescricao: '',
        footerContatos: '',

        sociais: [],
        sociaisYoutube: '',
        sociaisFacebook: '',
        sociaisTwitter: '',
        sociaisInstagram: '',

        listaProdutos: []
      
      }
    },
    created() {

      // Footer
      this.$http
      .get(this.$apiUrl + "/institucional/telas/rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.footer = retornoAPI

        this.footer_bg = this.$apiUrlIndex + this.footer[0].institucionalImg[0].urlImagem
        this.footerDescricao = this.footer[0].descricao1
        this.footerContatos = this.footer[0].descricao2

      });

      // Redes Sociais
      this.$http
      .get(this.$apiUrl + "/institucional/telas/links%20redes%20sociais")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.sociais = retornoAPI

        this.sociaisYoutube = this.sociais[0].campoExtra1
        this.sociaisFacebook = this.sociais[0].campoExtra2
        this.sociaisTwitter = this.sociais[0].linkBtn1
        this.sociaisInstagram = this.sociais[0].linkBtn2

      });


      // Lista de Produtos
      this.$http
      .get(this.$apiUrl + "/produto") 
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.listaProdutos = retornoAPI.filter(x => x.visivel) 

          this.listaProdutos.sort(function (a, b) {
          if (a.nomeProduto > b.nomeProduto) {return 1;}        
          if (a.nomeProduto < b.nomeProduto) {return -1;}       
          return 0;
        
        })

      });

      

    }
  }

</script>