<template>

  <div class="">

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <section class="blog_area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0">
            <div class="col heading-section mb-5">          
              <h2 class="title-h2 text-blue">{{ blogTitulo }}</h2>
            </div>
            <div class="blog_left_sidebar">
              <article class="blog_item">
                <div class="blog_item_img">
                  <img 
                    class="card-img rounded-0"
                    :src="blog_img"
                  >
                </div>
                <div class="blog_details">
                  <p v-html="blogDescricao"></p>                  
                </div>
              </article>
              
            </div>
          </div>

          <!-- Side -->
          <div class="col-lg-4">
            <div class="blog_right_sidebar">             
              <aside class="single_sidebar_widget popular_post_widget">
                <h5 class="text-blue mb-5">Notícias Recentes</h5>
                <div 
                  class="media post_item"
                  v-for="noticia in blog"
                  :key="noticia.id"
                  >
                  <img :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem">
                  <div class="media-body">
                    <a 
                      href="#"
                      @click="carregaNoticia(noticia.institucionalId)"
                    >
                      <h3 class="text-blue">{{ noticia.nome }}</h3>
                    </a>
                    <p>{{ currentDateTime(noticia.data1) }}</p>
                  </div>
                </div>
              </aside>              
            </div>
          </div>
        </div>
      </div>
    </section>

    
  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'
import moment from 'moment'

export default {
  components: {
    TopoInternas,
  },
  data() {
    return {
      topoBlog: [],
      topoTitulo: '',
      topo_bg: '',

      blog: [],
      blogTitulo: '',
      blog_img: '',
      blogDescricao: '',

    }
  },
  created() {

    // Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20blog")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoBlog = retornoAPI
      
      this.topo_bg = this.$apiUrlIndex + this.topoBlog[0].institucionalImg[0].urlImagem
      this.topoTitulo = this.topoBlog[0].nome

    });

    // Blog
    this.$http
    .get(this.$apiUrl + "/institucional/telas/noticias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter(x => x.visivel)
      
      this.blogTitulo = this.blog[0].nome
      this.blog_img = this.$apiUrlIndex + this.blog[0].institucionalImg[0].urlImagem
      this.blogDescricao = this.blog[0].descricao1

    });

    this.carregaNoticia(this.$route.params.idNoticia) 

  },
  methods: {

    carregaNoticia(idNoticia) {    
      this.$http
      .get(this.$apiUrl + "/institucional/" + idNoticia)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.noticia = retornoAPI

        this.blogTitulo = this.noticia.nome
        this.blog_img = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem
        this.blogDescricao = this.noticia.descricao1

        if (this.noticia.institucionalImg[0] != null) {          
          this.blog_img = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem;
        }
        else {          
          this.blog_img = '';
        }
          
      });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>

<style scoped>

  @import '/css/timeline.css';

</style>

