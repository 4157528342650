<template>
  
  <div class="header-area">
    <div class="main-header header-sticky">
      <div 
        class="container-fluid px-lg-4"
      >
        <b-navbar toggleable="lg" class="ftco_navbar ftco-navbar-light">
          <b-navbar-brand>
            <router-link to="/" class="navbar-brand">
              <img class="logo-topo" src="/img/01.png" alt="Logo">
            </router-link>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="fas fa-bars icon-menu text-neon"></i>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>

            <b-navbar-nav 
              class="navbar-nav menu-xl ml-auto"
              id="navigation"
            >
              <b-nav-item>
                <li class="nav-item">
                  <router-link exact 
                    to="/" 
                    class="nav-link"
                  >
                    Home
                  </router-link>
                </li>
              </b-nav-item>

              <b-nav-item>
                <li class="nav-item">
                  <router-link 
                    to="/sobre" 
                    class="nav-link"
                  >
                    Sobre Nós
                  </router-link>
                </li>
              </b-nav-item>


              <!-- Dropdown -->
              <b-nav-item class="hidden-sm">
                <li class="nav-item">
                  <div class="dropdown">
                    <a class="nav-link">Nossos Produtos</a>
                    <div class="dropdown-content">
                      <a 
                        v-for="item in listaProdutos"
                        :key="item.produtoId"
                        href="#" 
                        @click="$redirect_reload('/produtos/' + item.nomeProduto.replaceAll(' ','-').replaceAll('/','') + '/' + item.produtoId)"
                      >
                        {{ item.nomeProduto }}
                      </a>
                    </div>
                  </div>
                </li>
              </b-nav-item>

              <b-nav-item>
                <li class="nav-item">
                  <router-link 
                    to="/blog" 
                    class="nav-link"
                  >
                    Blog
                  </router-link>
                </li>
              </b-nav-item>

              <b-nav-item>
                <li class="nav-item">
                  <router-link 
                    to="/contato" 
                    class="nav-link"
                  >
                    Contato
                  </router-link>
                </li>
              </b-nav-item>   

              <b-nav-item class="hidden-lg hidden-xl">
                <h5 class="product-list-mobile-title">Nossos Produtos</h5>
                <ul class="product-list-mobile">
                  <li
                    class="nav-item"
                    v-for="item in listaProdutos"
                    :key="item.produtoId"
                  >
                    <a 
                      class="nav-link"
                      href="#" 
                      @click="$redirect_reload('/produtos/' + item.nomeProduto.replaceAll(' ','-').replaceAll('/','') + '/' + item.produtoId)"
                    >
                      {{ item.nomeProduto }}
                    </a>
                  </li>
                </ul>
              </b-nav-item>
                    
            </b-navbar-nav>

          </b-collapse>

          <!-- Button -->
          <div class="buttons hidden-sm-md">
            <ul>
              <li class="button-header">
                <a 
                  href="#" 
                  class="header-btn m-right-10 hidden-lg"
                > 
                  <i class="fas fa-phone-alt"></i>
                  <span>{{ headerTelefone }}</span>
                </a>

                <a 
                  :href="headerwhatsLink" 
                  target="_blank"
                  class="btn-rounded btn-whatsapp"
                >
                  {{ headerwhatsBtn }}
                </a>
              </li>
            </ul>
          </div>

          
        </b-navbar>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {

      header: [],
      headerTelefone: '',
      headerwhatsBtn: '',
      headerwhatsLink: '',

      listaProdutos: [],
      options: ['Produto A', 'Produto B'],
      produtoSelected: 'Nossos Produtos'

    }
  },
  created() {

    // Redes Sociais
    this.$http
    .get(this.$apiUrl + "/institucional/telas/cabeçalho")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.header = retornoAPI

      this.headerTelefone = this.header[0].nome
      this.headerwhatsBtn = this.header[0].textoBtn1
      this.headerwhatsLink = this.header[0].linkBtn1

    });


    // Lista de Produtos
    this.$http
    .get(this.$apiUrl + "/produto") 
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.listaProdutos = retornoAPI.filter(x => x.visivel) 

        this.listaProdutos.sort(function (a, b) {
        if (a.nomeProduto > b.nomeProduto) {return 1;}        
        if (a.nomeProduto < b.nomeProduto) {return -1;}       
        return 0;
       
      })

    });


    // Segmento Produtos
    // this.$http
    // .get(this.$apiUrl + "/cadastro%20produtos") 
    // .then((res) => res.json())
    // .then((retornoAPI) => {
    //   this.segmentoProdutos = retornoAPI.filter((x) => x.visivel && x.segmentoInstNivel1.nomeSegmento == this.??????.segmentoInstNivel1.nomeSegmento) 

    //     this.segmentoProdutos.sort(function (a, b) {
    //     if (a.ordem > b.ordem) {return 1;}        
    //     if (a.ordem < b.ordem) {return -1;}       
    //     return 0;
       
    //   })

    // });

  }, 
  methods: {

    // carregaSegmento(idSegmento) {

    //   this.$http
    //   .get(this.$apiUrl + "/institucional/" + idSegmento)
    //   .then((res) => res.json())
    //   .then((retornoAPI) => {
    //     this.segmentoProdutos = retornoAPI.filter(x => x.visivel)
    //     this.segmentoProdutos.sort(function (a, b) {
    //       if (a.ordem > b.ordem) { return 1; }   
    //       if (a.ordem < b.ordem) { return -1; } 
    //       return 0;
    //     })

    //   });

    // }      
    
  }
  
}
</script>

<style scoped>

  @import '/css/header.css';

</style>