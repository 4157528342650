<template>

	<div class="">

		<!-- Topo Internas -->
		<TopoInternas :topoTitulo="topoTitulo" :topo_bg="topo_bg" />


		<section class="contato section_pad-100">
			<div class="container">
				<div class="row">
					<!-- <div class="col-12">
							<h2 class="contact-title">Get in Touch</h2>
						</div> -->
					<div class="col-lg-3">
						<h5 class="text-blue mb-5">Entre em contato</h5>
						<div class="media contact-info">
							<div class="media-body">
								<p v-html="contatoEndereco"></p>
							</div>
						</div>
						<div class="media contact-info">
							<div class="media-body">
								<p v-html="contatoTelefone"></p>

							</div>
						</div>
						<div class="media contact-info">
							<div class="media-body">
								<a href="#">
									<p>{{ contatoEmail }}</p>
								</a>
							</div>
						</div>
					</div>
					<div class="col-lg-8 offset-lg-1 mt-5 mt-lg-0">
						<form class="form-contact contact_form" action="contact_process.php" method="post"
							id="contactForm" novalidate="novalidate">
							<div class="row">

								<div class="col-sm-6">
									<div class="form-group">
										<input class="form-control valid" name="name" id="name" type="text"
											onfocus="this.placeholder= ''" onblur="this.placeholder= 'Nome'"
											placeholder="Nome" v-model="nome">
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group">
										<input class="form-control valid" name="email" id="email" type="email"
											onfocus="this.placeholder= ''" onblur="this.placeholder='E-mail'"
											placeholder="E-mail" v-model="email">
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group">
										<input class="form-control valid" name="name" id="name" type="text"
											onfocus="this.placeholder= ''" onblur="this.placeholder= 'Telefone'"
											placeholder="Telefone" v-model="telefone">
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group">
										<input class="form-control valid" name="name" id="name" type="text"
											onfocus="this.placeholder= ''" onblur="this.placeholder= 'Cidade'"
											placeholder="Cidade" v-model="cidade">
									</div>
								</div>

								<div class="col-12">
									<div class="form-group">
										<textarea class="form-control w-100" name="message" id="message" cols="30"
											rows="9" onfocus="this.placeholder= ''"
											onblur="this.placeholder= 'Mensagem'" placeholder="Mensagem"
											v-model="conteudo">
											</textarea>

									</div>
								</div>
							</div>
							<div class="form-group mt-3">
								<button type="button" class="btn-rounded btn-4" @click="enviarDadosFormulario">
									Enviar Mensagem
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>


	</div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'

export default {
	components: {
		TopoInternas,
	},
	data() {
		return {

			topoContato: [],
			topoTitulo: '',
			topo_bg: '',


			contatoEmail: '',
			contatoEndereco: '',
			contatoTelefone: '',

			nome: '',
			email: '',
			telefone: '',
			cidade: '',
			conteudo: '',

			// Objeto Contato      
			Contato: {
				NomeEmpresa: "Eisteel",
				Assunto: "Contato",
				Destinatario: "",
				EmailResposta: "",
				TextoEmail: ""
			}

		}
	},
	created() {

		// Topo
		this.$http
			.get(this.$apiUrl + "/institucional/telas/topo%20contato")
			.then((res) => res.json())
			.then((retornoAPI) => {
				this.topoContato = retornoAPI

				this.topo_bg = this.$apiUrlIndex + this.topoContato[0].institucionalImg[0].urlImagem
				this.topoTitulo = this.topoContato[0].nome

			});


		// Contatos
		this.$http
			.get(this.$apiUrl + "/institucional/telas/info%20contato")
			.then((res) => res.json())
			.then((retornoAPI) => {
				this.contato = retornoAPI

				this.contatoEmail = this.contato[0].referencia
				this.contatoEndereco = this.contato[0].descricao1
				this.contatoTelefone = this.contato[0].descricao2

			});

	},
	methods: {
		enviarDadosFormulario() {

			//Email que irá receber os dados do formulário preenchido (empresa site)
			this.Contato.Destinatario = this.contatoEmail
			// this.Contato.Destinatario = "thiagokdma@gmail.com"

			//Email de quem preencheu formulário e enviou email (usuário/cliente)
			this.Contato.EmailResposta = this.email

			// Dados que aparecerão no corpo do email
			this.Contato.TextoEmail =
				"Nome: " + this.nome +
				" <br /> Email: " + this.email +
				" <br /> Telefone: " + this.telefone +
				" <br /> Cidade: " + this.cidade +
				" <br /> Descrição: " + this.conteudo

			//SE NAO TIVER ANEXO:
			// console.log(this.Contato)
			if (this.nome == '' || this.email == '' || this.cidade == '' || this.mensagem == '') {
				this.$mensagem_normal("Preencha todos os campos");

			} else {

				this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
					.then(response => {
						if (response.ok)
							this.$mensagem_normal("Enviado com sucesso!")
						this.nome = ''
						this.email = ''
						this.telefone = ''
						this.cidade = ''
						this.conteudo = ''
					}, error => {
						this.$mensagem_normal(error.bodyText)
						this.nome = ''
						this.email = ''
						this.telefone = ''
						this.cidade = ''
						this.conteudo = ''
					});
			}

		}
	}

}

</script>



