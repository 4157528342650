<template>

  <div class="home">

    <!-- Carousel -->
    <!-- <div class="hidden-sm">
      <b-carousel
        id="carousel-fade"
        fade
        indicators
        :interval="5000"
        img-width="1024"
        img-height="400"
      >
        <b-carousel-slide
          v-for="slide in slider" :key="slide.id"
          :img-src="$apiUrlIndex + slide.institucionalImg[0].urlImagem"
        >
          <div class="row">
            <div class="col-md-9 offset-md-3 col-lg-10 offset-lg-2">
              <div class="carousel-content">
                <h1>{{ slide.nome }}</h1>
                <p 
                  class="carousel-text mt-2 mt-xl-5 hidden-sm"
                  v-html="slide.descricao1"
                >
                </p>

                <div class="mt-5">
                  <a 
                    :href="slide.linkBtn1" 
                    class="btn-rounded btn-1"
                  >
                    {{ slide.textoBtn1 }}
                  </a> 
                </div>       
              </div>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div> -->

    <!-- Topo -->
    <section
      class="topo-home d-flex align-items-center section_pad-100 set-img"
      v-for="slide in slider" :key="slide.id"
      :style="{ backgroundImage: 'url(' + $apiUrlIndex + slide.institucionalImg[0].urlImagem + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-4 col-xl-7 offset-xl-5">
            <div class="topo-content">
              <h1>{{ slide.nome }}</h1>
              <p 
                class="carousel-text mt-2 mt-xl-5 hidden-sm"
                v-html="slide.descricao1"
              >
              </p>

              <div class="mt-5">
                <a 
                  :href="slide.linkBtn1" 
                  class="btn-rounded btn-1"
                >
                  {{ slide.textoBtn1 }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Nossos Produtos -->
    <section class="popular-items section-padding40">
      <div class="container">
        <!-- Section Tittle -->
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8 col-md-10 col-sm-10">
            <div class="section-tittle text-center mb-60">
              <span>{{ sectionProdutosPreTitulo }}</span>
              <h2 class="text-blue">{{ sectionProdutosTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div 
            class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-5 mb-lg-0"
            v-for="item in listaProdutos.slice(0, 3)"
            :key="item.produtoId"
          >
            <div class="single-items text-center mb-30">
              <div class="items-top">
                <img 
                  v-if="item.imagens != ''" 
                  :src="$apiUrlIndex + item.imagens[0].urlImg"
                >
              </div>
              <div class="items-bottom">
                <h4 style="min-height: 60px;">
                  <router-link 
                    :to="'/produtos/' + item.nomeProduto.replaceAll(' ','-').replaceAll('/','') + '/' + item.produtoId"
                  >
                    {{ item.nomeProduto }} 
                  </router-link>
                </h4>

                <h6>{{ item.referencia }}</h6>
                <p v-html='item.resuno'></p>                
              </div>
              <router-link 
                :to="'/produtos/' + item.nomeProduto.replaceAll(' ','-').replaceAll('/','') + '/' + item.produtoId" 
                class="btn-rounded btn-2"
              >
                Mais Detalhes
              </router-link>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <!-- Qualidade -->
    <div class="support-company-area pb-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-center mb-5 mb-lg-0">
            <iframe 
              width="100%" 
              height="320" 
              :src="qualidadeVideo" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen
            >
            </iframe>
          </div>
          <div class="col-md-10 col-lg-5 offset-lg-1">
            <div class="right-caption">
              <!-- Section Tittle -->
              <div class="section-tittle mb-30">
                <span>{{ qualidadePreTitulo }}</span>
                <h2 
                  class="text-blue"
                  v-html="qualidadeTitulo"
                ></h2>
                  
              </div>
              <div class="support-caption">
                <p 
                  class="mb-40"
                  v-html="qualidadeDescricao"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Variedade -->
    <div 
      class="our-services section_pad-100 position-relative"
      style="background-color: #002a73;"
    >
      <div class="container">
        <div class="row justify-content-sm-center">
          <div class="cl-xl-7 col-lg-8 col-md-10">
            <!-- Section Tittle -->
            <div class="section-tittle text-center mb-70">
              <span class="text-light">{{ sectionVariedadesPreTitulo }}</span>
              <h2 class="text-white">{{ sectionVariedadesTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div 
            class="col-lg-3 col-md-6 col-sm-6"
            v-for="variedade in variedades" 
            :key="variedade.id"
          >
            <div class="single-services text-center">
              <div class="services-ion">
                <img :src="$apiUrlIndex + variedade.institucionalImg[0].urlImagem">
              </div>
              <div class="services-cap">
                <!-- <h5><a href="#">Pet Boarding</a></h5> -->
                <h4 class="text-white">{{ variedade.nome }}</h4>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <!-- CTA -->
    <CTA />

    <!-- Blog -->
    <section class="popular-items section-padding40">
      <div class="container">
        <!-- Section Tittle -->
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8 col-md-10 col-sm-10">
            <div class="section-tittle text-center mb-60">
              <span>Blog</span>
              <h2 class="text-blue">Últimas Notícias</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div 
            class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
            v-for="noticia in blog.slice(0, 3)"
            :key="noticia.id"
          >
            <div class="single-blogs mb-30">
              <div class="blog-img blog-img2">
                <img :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem">
              </div>
              <div class="blogs-cap px-3 py-4">
                <h5><router-link :to="/blog/ + noticia.institucionalId">{{ noticia.nome }}</router-link></h5>
                <p v-html="noticia.resumo1"></p>
                <router-link 
                  :to="/blog/ + noticia.institucionalId"
                  class="link-blog"
                >
                  Leia Mais
                </router-link>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import CTA from '../shared/CTA.vue'

export default {
  components: {
    CTA
  },
  data() {
    return {

      slider: [],
      sectionProdutosPreTitulo: '',
      sectionProdutosTitulo: '',

      // Produtos
      segmentoProdutos: '',
      listaProdutos:[],

      qualidade: [],
      qualidadeVideo: '',
      qualidadePreTitulo: '',
      qualidadeTitulo: '',
      qualidadeDescricao: '',

      sectionVariedades: [],
      sectionVariedadesPreTitulo: '',
      sectionVariedadesTitulo: '',

      variedades: [],

      blog: [],

      
      
    }
  },
  created() {

    // Slider
    this.$http
    .get(this.$apiUrl + "/institucional/telas/slider")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.slider = retornoAPI.filter(x => x.visivel)
      this.slider.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }   
        if (a.ordem < b.ordem) { return -1; } 
        return 0;
      })

    });
    


    // Section Produtos
    this.$http
    .get(this.$apiUrl + "/institucional/telas/titulo%20produtos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sectionProdutos = retornoAPI
      
      this.sectionProdutosPreTitulo = this.sectionProdutos[0].nome
      this.sectionProdutosTitulo = this.sectionProdutos[0].campoExtra1

    });


    // Produtos
    this.$http
    .get(this.$apiUrl + "/produto") 
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.listaProdutos = retornoAPI.filter((x) => x.visivel && x.destaque) 
      this.listaProdutos.sort(function (a, b) {
        if (a.nomeProduto > b.nomeProduto) {return 1;}        
        if (a.nomeProduto < b.nomeProduto) {return -1;}       
        return 0;
      
      })
    });
    

    // Qualidade
    this.$http
    .get(this.$apiUrl + "/institucional/telas/qualidade")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.qualidade = retornoAPI
      
      this.qualidadeVideo = this.qualidade[0].linkBtn1
      this.qualidadePreTitulo = this.qualidade[0].nome
      this.qualidadeTitulo = this.qualidade[0].descricao1
      this.qualidadeDescricao = this.qualidade[0].descricao2

    });


    // Section Variedade
    this.$http
    .get(this.$apiUrl + "/institucional/telas/variedade%20titulo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sectionVariedades = retornoAPI

      this.sectionVariedadesPreTitulo = this.sectionVariedades[0].nome
      this.sectionVariedadesTitulo = this.sectionVariedades[0].campoExtra1

    });


    // Variedade
    this.$http
    .get(this.$apiUrl + "/institucional/telas/variedade%20itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.variedades = retornoAPI.filter(x => x.visivel)
      this.variedades.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }   
        if (a.ordem < b.ordem) { return -1; } 
        return 0;
      })

    });


    // Blog
    this.$http
    .get(this.$apiUrl + "/institucional/telas/noticias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter(x => x.visivel)
      


    });


  }
  
}

</script>

