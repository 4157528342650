<template>

  <div 
    class="slider-area slider-height2 slider-bg2"
    :style="{ backgroundImage: 'url(' + topo_bg + ')' }"
  >
    <div class="slider-active">
      <div class="single-slider d-flex align-items-center slider-height2">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-8 offset-md-4 col-lg-7 offset-lg-3">
              <div class="topo-internas text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["topo_bg", "topoTitulo"],
}
</script>