import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Sobre from '@/components/site/views/Sobre.vue'
import Produtos from '@/components/site/views/Produtos.vue'
import Blog from '@/components/site/views/Blog.vue'
import Contato from '@/components/site/views/Contato.vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/sobre', component: Sobre},
    {path: '/produtos/:nome/:id', component: Produtos},
    {path: '/blog', component: Blog},
    {path: '/blog/:idNoticia', component: Blog},
    {path: '/contato', component: Contato},

  ]
})