<template>
  
  <!-- CTA -->
  <section 
    class="cta section_pad-100 set-img"
    style="background-image: url(/img/14.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-7 cta-content text-center">
          <h2 class="title-cta">{{ ctaTitulo2 }} 
            <span class="title-max">{{ ctaTitulo1 }} </span> <span class="title-min"> {{ ctaTitulo3 }}</span></h2>
          <div class="mt-5">
            <a 
              :href="ctaBtnLink" 
              class="btn-rounded btn-2"
            >
              {{ ctaBtn }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
  export default {
    data() {
      return {

        cta: [],
        ctaTitulo1: '',
        ctaTitulo2: '',
        ctaTitulo3: '',
        ctaBtn: '',
        ctaBtnLink: '',

      }
    },
    created() {

      // CTA
      this.$http
      .get(this.$apiUrl + "/institucional/telas/call%20action")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.cta = retornoAPI

        this.ctaTitulo2 = this.cta[0].nome
        this.ctaTitulo1 = this.cta[0].campoExtra1
        this.ctaTitulo3 = this.cta[0].campoExtra2
        this.ctaBtn = this.cta[0].textoBtn1
        this.ctaBtnLink = this.cta[0].linkBtn1

      });

    }
  }
</script>

<style scoped>

  .cta-content .title-cta .title-max {
    display: block;
    line-height: 100px;
    font-size: 11rem;
    margin-bottom: 20px;
  }

  .cta-content .title-cta {
    color: #002a73;
    font-size: 5rem;
    font-weight: 700;
  }

  .cta-content .title-cta .title-min {
    font-size: 2.2rem;
  }

  .cta-content .text-cta {
    color: #f9f9f9;
    font-size: 18px;
    font-weight: 500;
  }

  .icon-whats {
    font-size: 30px;
  }

  

  /********************
  Responsive
  ********************/

  /* Smartphones */
  @media (max-width:767px) {
    .cta-content .title-cta .title-max {
    line-height: 70px;
    font-size: 6rem;
    margin-bottom: 0px;
  }

  .cta-content .title-cta {
    color: #002a73;
    font-size: 3.5rem;
    font-weight: 700;
  }

  .cta-content .title-cta .title-min {
    font-size: 1.2rem;
  }
  }

  /* Smartphones + tablets */
  @media (max-width:991px) {
  
  }

  /* Tablets */
  @media (min-width:768px) and (max-width:991px) {
    
  }

  /* Netbooks */
  @media (min-width:992px) and (max-width:1199px) {
    
  }

  /* Desktop + Notbooks */
  @media (min-width:1200px) {
    .cta {
      height: 100vh;
    }
  }


</style>

