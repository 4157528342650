<template>

  <div class="">

    <!-- Topo Internas -->
    <TopoInternas
      :topoTitulo="topoTitulo"
      :topo_bg="topo_bg"
    />


    <!-- Sobre -->
    <div class="support-company-area section_pad-100">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-6 mb-5 mb-lg-0 text-center order-1 order-lg-2">
            <img :src="sobre_img">
          </div>
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="sobre-content">
              <p v-html="sobreDescricao"></p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MVV (Tab) vue -->
    <!-- <div class="overflow-hidden m_bottom-50">
      <b-tabs 
        content-class="mt-3" 
        fill
        active-nav-item-class="font-weight-bold text-dark"
      >
        <b-tab 
        v-for="item in mvv" 
        :key="item.id"
        :title="item.nome" 
        active
        >
          <div class="container-fluid section_pad-100"
          style="background-color: #f2f0ea;">
            <div class="row justify-content-center ftco-section">
              <div class="col-md-10 col-lg-9">
                <div class="mvv-item text-center">
                  <p v-html="item.descricao1"></p>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div> -->

    <!-- Video -->
    <section class="video mb-100">
      <div class="container">
        <div class="row mb-5">
          <div class="col text-center heading-section">          
            <div class="text-center">
              <h2 class="title-h2 text-blue">{{ videoTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <iframe 
              width="100%" 
              height="450" 
              :src="videoLink" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen
            >
            </iframe>
            
            <p 
              class="mt-5"
              v-html="videoDescricao"
            ></p>
          </div>
        </div>
      </div>
    </section>


    <!-- Galeria -->
    <section 
      class="galeria section_pad-100"
      style="background-color: #f2f0ea;"
    >
      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col text-center heading-section">          
            <div class="text-center">
              <h2 class="title-h2 text-blue">{{ galeriaTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div 
            class="col-6 col-md-4 col-lg-2 galeria-item px-0"
            v-for="imagem in galeriaImagens"
            :key="imagem.id"
          >
            <img class="w-100" :src="$apiUrlIndex + imagem.urlImagem">
          </div>
        </div>
      </div>
    </section> 

    <!-- Timeline -->
    <!-- <section class="section_pad-100">
      <div class="container">
        <div class="row mb-5">
          <div class="col text-center heading-section">          
            <div class="text-center">
              <h2 class="title-h2 text-blue">Histórico</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 offset-2 col-md-6 offset-md-6">
            <ul class="timeline">
              <li 
                v-for="time in timeline"
                :key="time.id"
              >
                <h2 class="time-data">{{ time.nome }}</h2>
                <p 
                  class="time-text"
                  v-html="time.descricao1"></p>
              </li>             
            </ul>
          </div>
        </div>
      </div>
    </section>  -->

  </div>
</template>

<script>

import TopoInternas from '../shared/TopoInternas.vue'

export default {
  components: {
    TopoInternas,
  },
  data() {
    return {

      topoSobre: [],
      topoTitulo: '',
      topo_bg: '',

      sobre: [],
      sobre_img: '',
      sobreDescricao: '',

      mvv: [],

      video: [],
      videoTitulo: '',
      videoDescricao: '',
      videoLink: '',

      galeria: [],
      galeriaTitulo: [],
      galeriaImagens: [],

      timeline: []
      
    }
  },
  created() {

    // Topo
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoSobre = retornoAPI
      
      this.topo_bg = this.$apiUrlIndex + this.topoSobre[0].institucionalImg[0].urlImagem
      this.topoTitulo = this.topoSobre[0].nome

    });


    // Sobre
    this.$http
    .get(this.$apiUrl + "/institucional/telas/descricao%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI
      
      this.sobre_img = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem
      this.sobreDescricao = this.sobre[0].descricao1

    });


    // MVV
    this.$http
    .get(this.$apiUrl + "/institucional/telas/missao,%20visao,%20valores")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.mvv = retornoAPI.filter(x => x.visivel)
      this.mvv.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }   
        if (a.ordem < b.ordem) { return -1; } 
        return 0;
      })

    });


    // video
    this.$http
    .get(this.$apiUrl + "/institucional/telas/video")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.video = retornoAPI
      
      this.videoTitulo = this.video[0].nome
      this.videoDescricao = this.video[0].descricao1
      this.videoLink = this.video[0].linkBtn1

    });


    // Galeria
    this.$http
    .get(this.$apiUrl + "/institucional/telas/galeria")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.galeria = retornoAPI
      
      this.galeriaTitulo = this.galeria[0].nome
      this.galeriaImagens = this.galeria[0].institucionalImg

    });
    

    // Timeline
    this.$http
    .get(this.$apiUrl + "/institucional/telas/historico")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.timeline = retornoAPI.filter(x => x.visivel)
      this.timeline.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }   
        if (a.ordem < b.ordem) { return -1; } 
        return 0;
      })

    });


  }
  
}

</script>

<style scoped>

  @import '/css/timeline.css';

</style>

